import React, { Component } from "react"


/* Component Bet */
class Bet extends Component {

    render = () => {

        const { onClick } = this.props

        return <div onClick={() => onClick()} className="game-button bet-button">BET 2X</div>
    }

}

export default Bet