import React, { Component } from 'react'

class Arc extends Component {

    render = () => {

        const { locale } = this.props

        let text = ['D', 'E', 'A', 'L', 'E', 'R', ' ', 'Q', 'U', 'A', 'L', 'I', 'F', 'I', 'E', 'S', ' ', 'W', 'I', 'T', 'H', ' ', 'P', 'A', 'I', 'R', ' ', 'O', 'F', ' ', '4', 's', ' ', 'O', 'R', ' ', 'B', 'E', 'T', 'T', 'E', 'R']

        if (locale === 'ru') {
            text = ['Д', 'И', 'Л', 'Е', 'Р', ' ', 'К', 'В', 'А', 'Л', 'И', 'Ф', 'И', 'К', 'У', 'Е', 'Т', 'С', 'Я', ' ', 'С', ' ', 'П', 'А', 'Р', 'О', 'Й', ' ', '4', '-', 'Х', ' ', 'И', 'Л', 'И', ' ', 'В', 'Ы', 'Ш', 'Е']
        }

        return (
            <div className='table-arc'>
                <div className='text'>
                    {
                        text.map((letter, i) => {
                            return (<p key={`l-${i}`}>{letter === ' ' ? <span>&nbsp;</span> : letter}</p>)
                        })
                    }
                </div>
            </div>
        )
    }

}

export default Arc