import React, { Component } from "react"

/* Alicorn */
import { Chip, Picture } from "@alicorn/ep-ui"

/* Constants */
import { env, utils, sound } from '../constants'

/* Fields */
const BOXES = [0, 1]


/* Widget Chips */
class Chips extends Component {

    constructor() {
        super()

        this.state = {
            list: []
        }
    }

    setChip = (value, index) => {

        const { ante: anteList, bonus: bonusList, setStake, selected, setSelected, list, setList, balance, game, currency, volume, box } = this.props

        const ante = utils.check(anteList[box], 0)
        const bonus = utils.check(bonusList[box], 0)

        const secondBox = box ? 0 : 1
        const secondBoxTotal = anteList[secondBox] * 4 + bonusList[secondBox]

        if (selected !== index) {
            setSelected(index)
            if (volume) {
                sound.play('selectchip', 0.2)
            }
        }
        else {

            if (((ante + parseInt(value)) * 4 + bonus + secondBoxTotal) > balance) {
                utils.toggleError(utils.translate("You don't have enough funds"), "error", "nomoney")
            }
            else if (ante + parseInt(value) > game.max) {
                utils.toggleError(`${utils.translate("Maximum bid")} ${utils.getWithCurrency(game.max, currency)}`, "error", "maxbidante")
            }
            else {
                setStake({ ante: ante + parseInt(value), bonus: bonus }, 0)

                list.push({ value, type: "add", target: "ante", box: 0 })
                setList(list)

                if (volume) {
                    sound.play('sound', 0.2)
                }
            }

        }

    }

    clear = () => {

        const { ante, setStake, setList, volume } = this.props

        if (utils.valueLength(ante)) {

            setStake({ ante: 0, bonus: 0 }, 0)
            setStake({ ante: 0, bonus: 0 }, 1)
            setList([])

            if (volume) {
                sound.play('chip2', 0.2)
            }
        }

    }

    cancel = () => {

        const { ante, bonus, setStake, list, setList, volume } = this.props
        let cancel = list

        const index = list.findLastIndex(e => e.type === "add")

        if (index > -1) {

            const last = cancel[index]
            let stake = { ante: utils.check(ante[last.box], 0), bonus: utils.check(bonus[last.box], 0) }

            if (last.target === 'ante' && !last.double) {
                if (stake.ante - last.value >= 0) {
                    stake.ante -= last.value
                    list[index].type = "remove"
                }
                if (last.double) {
                    if (stake.bonus) {
                        stake.bonus /= 2
                        const ind = list.findLastIndex(e => e.type === "add" && e.target === "bonus")
                        list[ind].type = "remove"
                    }
                }
            }
            if (last.target === 'bonus' && !last.double) {
                if (stake.bonus - last.value >= 0) {
                    stake.bonus -= last.value
                    const ind = list.findLastIndex(e => e.type === "add" && e.target === "bonus")
                    list[ind].type = "remove"
                }
            }

            if (last.double) {
                for (const box of BOXES) {
                    const tempStake = { ante: utils.check(ante[box], 0) / 2, bonus: utils.check(bonus[box], 0) / 2 }
                    setStake(tempStake, box)
                    const anteInd = list.findLastIndex(e => e.type === "add" && e.target === "ante" && e.double === true)
                    list[anteInd].type = "remove"
                    if (bonus[box]) {
                        const bonusInd = list.findLastIndex(e => e.type === "add" && e.target === "bonus" && e.double === true)
                        list[bonusInd].type = "remove"
                    }
                }
            } else {
                setStake(stake, last.box)
            }

            setList(cancel)

            if (volume) {
                sound.play('chip2', 0.2)
            }
        }
    }

    double = () => {

        const { ante: anteList, bonus: bonusList, setStake, list, setList, balance, game, currency, volume } = this.props

        let count = 0
        let total = 0
        let maxbid = null

        let stakes = [{ ante: 0, bonus: 0 }, { ante: 0, bonus: 0 }]

        for (const box of BOXES) {
            if (anteList[box]) {
                stakes[box].ante = anteList[box]
                total += anteList[box] * 3
                if (anteList[box] * 2 > game.max) {
                    maxbid = "maxbidante"
                }
                if (bonusList[box]) {
                    stakes[box].bonus = bonusList[box]
                    total += bonusList[box]
                    if (bonusList[box] * 2 > game.maxBonus) {
                        maxbid = "maxbidbonus"
                    }
                }
            }
        }

        if (total * 2 > balance) {
            utils.toggleError(utils.translate("You don't have enough funds"), "error", "nomoney")
            return
        }

        if (maxbid) {
            const text = maxbid === "maxbidante" ? "Maximum bid" : "Maximum bid for bonus"
            const value = maxbid === "maxbidante" ? game.max : game.maxBonus
            utils.toggleError(`${utils.translate(text)} ${utils.getWithCurrency(value, currency)}`, "error", maxbid)
            return
        }

        for (const box of BOXES) {

            const ante = utils.check(anteList[box], 0)
            const bonus = utils.check(bonusList[box], 0)

            setStake({ ante: ante * 2, bonus: bonus * 2 }, box)

            if (bonus) {
                list.push({ value: ante, type: "add", target: "bonus", double: true, box })
            }

            if (ante) {
                list.push({ value: ante, type: "add", target: "ante", double: true, box })
            }

            setList(list)
            count = count + 1
        }

        if (volume && count > 0) {
            sound.play('sound', 0.2)
        }

    }


    refresh = () => {

        const { ante: anteList, last: lastList, setStake, list, setList, balance, volume } = this.props

        let count = 0
        let total = 0

        for (const box of BOXES) {
            const last = utils.check(lastList[box], 0)
            total += last.ante * 3 + last.bonus
        }

        if (total > balance) {
            utils.toggleError(utils.translate("You don't have enough funds"))
            return
        }

        for (const box of BOXES) {

            const ante = utils.check(anteList[box], 0)
            const last = utils.check(lastList[box], 0)

            if (ante === 0 && (last && last.ante > 0)) {

                setStake(last, box)

                if (last.ante) {
                    list.push({ value: last.ante, type: "add", target: "ante", box })
                }
                if (last.bonus) {
                    list.push({ value: last.bonus, type: "add", target: "bonus", box })
                }

                setList(list)
                count = count + 1
            }
        }

        if (volume && count > 0) {
            sound.play('repeat', 0.2)
        }
    }

    render = () => {

        const { selected, ante, last, chips } = this.props

        let total = 0
        if (last && Array.isArray(last) && last.length > 0) {
            last.forEach(item => {
                if (item.ante && parseFloat(item.ante) > 0) {
                    total = total + parseFloat(item.ante)
                }
            })
        }

        const btnStyle = utils.valueLength(ante) > 0 ? { opacity: 1 } : { opacity: 0.5, cursor: 'default' }
        const refreshStyle = utils.check(ante[0], 0) === 0 && total > 0 ? { opacity: 1 } : { opacity: 0.5, cursor: 'default' }

        return (
            <div className="chips-container">

                <div onClick={() => this.clear()} className="chips-button" style={btnStyle}>
                    <Picture src={`${env.mediapoint}/images/delete.png`} alt="Delete" />
                    {utils.translate('Clear')}
                </div>

                <div onClick={() => this.cancel()} className="chips-button" style={btnStyle}>
                    <Picture src={`${env.mediapoint}/images/cancel.png`} alt="Cancel" />
                    {utils.translate('Cancel')}
                </div>

                <div className="chips-box">
                    {chips.map((chip, index) =>
                        <div onClick={() => this.setChip(chip.value, index)} key={`${index}`} className={`chip ${selected === index ? 'active' : 'disabled'}`}>
                            <Chip color={chip.color} value={chip.value} />
                        </div>
                    )}
                </div>

                <div onClick={() => this.double()} className="chips-button" style={btnStyle}>
                    <span>2x</span>
                    {utils.translate('Double')}
                </div>

                <div onClick={() => this.refresh()} className="chips-button" style={refreshStyle}>
                    <Picture src={`${env.mediapoint}/images/refresh.png`} alt="Refresh" />
                    {utils.translate('Repeat')}
                </div>

            </div>
        )
    }

}

export default Chips