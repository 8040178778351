import React, { Component } from "react"

/* PureComponents */
import { Chip } from '@alicorn/ep-ui'

/* Constants */
import { utils, sound } from '../constants'
import { CHOICE } from "../constants/status"


/* Widget Rates */
class Rates extends Component {

    /* Get Color */
    getColor = value => {

        const { chips } = this.props

        let color = chips[0].color

        chips.forEach(item => {
            if (item.value <= value) {
                color = item.color
            }
        })

        return color
    }

    /* DRAW ANIMATION */
    _animation = (target) => {

        const { list, box } = this.props
        const final = list.filter(e => parseInt(e.box) === parseInt(box))

        return (
            <div className="animated-chips">
                {final.map((chip, index) => {
                    const color = this.getColor(chip.value)

                    if (chip.target === target) {
                        return (
                            <div className={`animated-chip ${chip.type === "add" ? "visible" : "hidden"}`} key={`${index}`}>
                                <Chip color={color} value={chip.value} />
                            </div>
                        )
                    }

                    return null
                })}
            </div>
        )
    }

    add = (target) => {

        const { status, selected, ante, bonus, setStake, list, setList, balance, game, chips, currency, volume, box, all } = this.props

        let stake = { ante: ante, bonus: bonus }

        let totalAnte = 0
        if (all.ante) {
            all.ante.forEach(item => {
                totalAnte = totalAnte + item
            })
        }

        let totalBonus = 0
        if (all.bonus) {
            all.bonus.forEach(item => {
                totalBonus = totalBonus + item
            })
        }

        if (status === "CHOICE" && chips[selected]) {
            const value = chips[selected].value

            if (target === 'ante') {
                stake.ante = ante + value
                totalAnte = totalAnte + value
            }
            else {
                if (!ante) {
                    return
                }
                stake.bonus = bonus + value
                totalBonus = totalBonus + value
            }

            if ((totalAnte * 3 + totalBonus) > balance) {
                utils.toggleError(utils.translate("You don't have enough funds"), "error", "nomoney")
                return
            }

            if ((ante + value > parseInt(game.max) && target === 'ante') || (bonus + value > parseInt(game.maxBonus) && target === 'bonus')) {
                if (target === 'ante') {
                    utils.toggleError(`${utils.translate("Maximum bid")} ${utils.getWithCurrency(game.max, currency)}`, "error", "maxbidante")
                }
                else {
                    utils.toggleError(`${utils.translate("Maximum bid for bonus")} ${utils.getWithCurrency(game.maxBonus, currency)}`, "error", "maxbidbonus")
                }
                return
            }

            setStake(stake, box)

            list.push({ value, type: "add", target, box })
            setList(list)

            if (volume) {
                sound.play('sound', 0.2)
            }
        }
    }

    /* DRAW ANTE */
    _ante = () => {

        const { ante, solution, result } = this.props
        const color = this.getColor(ante)

        const isHidden = solution === "FOLD" || (result && result.result === "lose")

        return (
            <div onClick={() => this.add('ante')} className="ante">
                <div className="ante-text">ANTE</div>
                <div className={`ante-chip-box ${(isHidden) ? "fold" : "hidden"}`}>{(ante && ante > 0) ? <Chip color={color} value={ante} /> : null}</div>
                {this._animation('ante')}
                {this._winRate("ante")}
            </div>
        )
    }

    /* ANTE & BONUS MULTIPLIER */
    _winRate = (type) => {

        const { result, bonusResult, ante, bonus } = this.props

        let isWinRate = type === "ante" ? (ante && result && result.anteMultiplier && parseInt(result.anteMultiplier) > 0) : (bonus && bonusResult && bonusResult.multiplier && parseInt(bonusResult.multiplier))

        if (isWinRate) {

            const value = type === "ante" ? ante * result.anteMultiplier : bonus * bonusResult.multiplier
            const multiplier = type === "ante" ? result.anteMultiplier : bonusResult.multiplier
            const color = this.getColor(value)

            return (
                <div className="rate-win-ab">
                    <div className="rate-win-ab-text">{multiplier}x</div>
                    <div className="rate-win-ab-chip">
                        <Chip color={color} value={value} />
                    </div>
                </div>
            )
        }
    }

    /* DRAW BONUS */
    _bonus = () => {

        const { ante, bonus, bonusResult, status, box } = this.props
        const color = this.getColor(bonus)

        const choice = status === CHOICE
        const isHidden = bonusResult && bonusResult.multiplier === 0
        const active = ((choice && ante) || bonus) && !isHidden
        const style = box === 0 ? "right" : "left"

        return (
            <div className={`bonus ${style}`} onClick={() => this.add('bonus')} style={{ opacity: active ? 1 : 0.5 }}>
                <div className="bonus-text">BONUS</div>
                <div className={`bonus-chip-box ${(isHidden) ? "fold" : "hidden"}`}>{(ante && ante > 0) ? <Chip color={color} value={bonus} /> : null}</div>
                {this._animation('bonus')}
                {this._winRate("bonus")}
            </div>
        )
    }

    /* BET MULTIPLIER */
    _winBet = () => {

        const { bet, ante, result, box } = this.props

        const style = box === 0 ? "right" : "left"

        if (bet && result && result.betMultiplier && parseInt(result.betMultiplier) > 0) {

            const value = ante * bet * result.betMultiplier

            const color = this.getColor(value)

            return (
                <div className="rate-win-bet">
                    <div className={`rate-win-bet-text ${style}`}>{result.betMultiplier}x</div>
                    <div className="rate-win-bet-chip">
                        <Chip color={color} value={value} />
                    </div>
                </div>
            )
        }
    }


    /* Draw BET */
    _bet = () => {

        const { status, bet, ante, solution, result, bottomBet } = this.props
        const value = ante * bet
        const color = this.getColor(value)

        return (
            <div onClick={() => bottomBet()} className="bet" style={{ opacity: status === "CHOICE" ? 0.6 : 1 }}>
                <div className="bet-text">BET</div>
                {(bet && bet > 0) ? <div className={`bet-chip-box ${(solution === "FOLD" || (result && result.result === "lose")) ? "fold" : "hidden"}`}> <Chip color={color} value={value} /></div> : null}
                {this._winBet()}
            </div>
        )
    }

    render = () => {

        const { status, ante, gameBox, box } = this.props

        const style = gameBox !== "all" ? "" : box === 0 ? "right" : "left"

        return (
            <div className={`rates ${style}`} style={{ opacity: status !== "CHOICE" && ante === 0 ? 0.3 : 1 }}>
                {this._bonus()}
                {this._ante()}
                {this._bet()}
            </div>
        )
    }

}

export default Rates